<template>
  <div>
    <div class="px-3 py-2" :style="`direction:${lang.dir}`">
      <b-sidebar
        aria-labelledby="sidebar-no-header-title"
        width="90%"
        no-header
        id="car_contract_view"
        backdrop
        style="direction: ltr"
        right
        title="add order"
        shadow
      >
        <template #default="{ hide }">
          <div class="m-1 text-start" style="direction:rtl">
            <div
              style="
                width: 150px;
                margin-right: 10px;
                background: #000;
                color: #fff;
                border-radius: 5px;
              "
              class="btn-primary btn-sm text-center"
            >
              <span style="font-size: 0.8em"> {{ lang.maint_contract }} </span>
            </div>
            <div
              @click="hide"
              id="vproconclose"
              style="
                width: 130px;
                text-align: center;
                float: left;
                margin-left: 5px;
                margin-top: -25px;
                cursor: pointer;
              "
              class="btn-danger btn-sm"
            >
              <span>{{ $parent.lang.close }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <v-row style="direction: rtl" class="m-2">
            <v-col cols="12">
              <div
                class="backBlack"
                style="padding: 0px; width: 100%; text-align: center"
              >
                
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 155px;
                    background: red !important;
                    font-size: 1.1rem;
                    color: #fff;"
                  @click="resetAllValues()"
                  v-b-toggle.add_new_card
                  >{{lang.add_new_card}}</b-button
                >
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 155px;
                    background: green !important;
                    font-size: 1.1rem;
                    color: #fff;"
                  @click="addInvoiceEd()"
                  v-b-toggle.add_invoice
                  >{{lang.add_invoice}}</b-button
                >
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 155px;
                    background: blue !important;
                    font-size: 1.1rem;
                    color: #fff;"
                  @click="addQuot()"
                  v-b-toggle.add_quotation
                  >{{lang.add_quotation}}</b-button
                >
                <!-- <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 155px;
                    background: blue !important;
                    font-size: 1.1rem;
                    color: #fff;
                  "
                  @click="addQuot()"
                  v-b-toggle.addContractCard
                  >{{ lang.update }}</b-button
                > -->
                
              </div>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <div style="padding:5px;border:1px solid #bbb;margin-bottom:5px;" class="backBlack text-center">{{lang.contract_details}}</div>
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack" :style="`width:100px`">{{ lang.contract_id }}</th>
                            <td>{{ project.contract_id }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.contract_date }}</th>
                            <!-- <td>{{ project.contract_date.substr(0,10) }}</td> -->
                            <td>{{ project.contract_date }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.start_date }}</th>
                            <td>{{ project.start_date }}</td>
                            <!-- <td>{{ project.start_date.substr(0,10) }}</td> -->
                        </tr>
                        <tr>
                            <th class="backBlack" :style="`width:100px`">{{ lang.expire_date }}</th>
                            <td>{{ project.expire_date }}</td>
                            <!-- <td>{{ project.expire_date.substr(0,10) }}</td> -->
                            <th class="backBlack" :style="`width:100px`">{{ lang.mobile }}</th>
                            <td>{{ project.customer_contacts[0].mobile }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.customer_name }}</th>
                            <td>{{ project.customer_name }}</td>
                        </tr>
                    </thead>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <div style="padding:5px;border:1px solid #bbb;margin-bottom:5px;" class="backBlack text-center">{{lang.invoices}}</div>
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack text-center" :style="`width:100px`">{{ lang.total }}</th>
                            <th class="backBlack" :style="`width:100px`">{{ lang.vat }}</th>
                            <th class="backBlack text-center" :style="`width:100px`">{{ lang.ftotal }}</th>
                        </tr>
                        <tr>
                            <td>{{ project.total }}</td>
                            <td>{{ project.vat }}</td>
                            <td>{{ project.ftotal }}</td>
                        </tr>
                    </thead>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <div style="padding:5px;border:1px solid #bbb;margin-bottom:5px;" class="backBlack text-center" v-if="project.con_type == 1">{{lang.contract_items}}</div>
                  <v-simple-table v-if="project.con_type == 1">
                    <thead>
                        <tr>
                            <th class="backBlack text-center" style="width:80px;">{{ lang.item_code }}</th>
                            <th class="backBlack">{{ lang.item_name }}</th>
                            <th class="backBlack text-center" style="width:80px;">{{ lang.item_price }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in project.contract_items" :key="index">
                            <td>{{ item.item_code }}</td>
                            <td>{{ item.item_name }}</td>
                            <td>{{ item.item_price }}</td>
                        </tr>
                    </tbody>
                  </v-simple-table>
                  <div style="padding:5px;border:1px solid #bbb;margin-bottom:5px;margin-top:5px;" class="backBlack text-center">{{lang.terms}}</div>
                  <v-simple-table v-for="(item,index) in project.contract_terms_titles" :key="index">
                    <thead>
                        <tr>
                          <th class="backBlack text-center">{{ item.title_name }}</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr v-for="(itm,ind) in item.terms" :key="ind">
                            <td style="text-wrap: wrap;white-space: pre-line !important;text-align:start">
                              <div style="text-wrap: wrap;white-space: pre-line !important;text-align:start">{{ itm.terms }}</div>
                            </td>
                        </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <div style="padding:5px;border:1px solid #bbb;margin-bottom:5px;" class="backBlack text-center" v-if="project.con_type == 1">{{ lang.cars }}</div>
                  <v-simple-table v-if="project.con_type == 1">
                    <thead>
                        <tr>
                            <th class="backBlack" style="width:80px;">{{ lang.plate_number }}</th>
                            <th class="backBlack">{{ lang.vin_number }}</th>
                            <th class="backBlack">{{ lang.model }}</th>
                            <th class="backBlack">{{ lang.car_year }}</th>
                            <th class="backBlack">{{ lang.meter_car }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in project.cars" :key="index">
                            <td>{{ item.plate_number }}</td>
                            <td>{{ item.vin }}</td>
                            <td>{{ item.model }}</td>
                            <td>{{ item.manufacturing_year }}</td>
                            <td>{{ item.meter_car }}</td>
                        </tr>
                    </tbody>
                  </v-simple-table>

                  <div style="padding:5px;border:1px solid #bbb;margin-bottom:5px;margin-top:5px;" class="backBlack text-center">{{lang.job_cards}}</div>
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center backBlack">{{ lang.received_date }}</th>
                            <th class="text-center backBlack">{{ lang.total }}</th>
                            <th class="text-center backBlack">{{ lang.vat }}</th>
                            <th class="text-center backBlack">{{ lang.ftotal }}</th>
                            <th class="text-center backBlack">{{ lang.status }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in project.jobs_list" :key="index">
                            <td>{{ item.received_date }}</td>
                            <!-- <td>{{ item.created_date.substr(0,10) }}</td> -->
                            <td>{{ item.total }}</td>
                            <td>{{ item.vat }}</td>
                            <td>{{ item.ftotal }}</td>
                            <td class="text-center" >{{ item[`status_${lang.langname}`] }}</td>
                        </tr>
                    </tbody>
                  </v-simple-table>
                  <div style="padding:5px;border:1px solid #bbb;margin-bottom:5px;margin-top:5px;" class="backBlack text-center">{{lang.invocies}}</div>
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center backBlack">{{ lang.invoice_date }}</th>
                            <th class="text-center backBlack">{{ lang.total }}</th>
                            <th class="text-center backBlack">{{ lang.vat }}</th>
                            <th class="text-center backBlack">{{ lang.ftotal }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in project.invoices_list" :key="index">
                            <td>{{ item.inv_date }}</td>
                            <!-- <td>{{ item.created_date.substr(0,10) }}</td> -->
                            <td>{{ item.total }}</td>
                            <td>{{ item.vat }}</td>
                            <td>{{ item.ftotal }}</td>
                        </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </b-sidebar>
    </div>
    
    <addCarContractCard ref="addCarContractCard" />
    <carConractAddInvoice ref="addCarInvoice" />
    <carConractAddQuot ref="addQuot" />
  </div>
</template>

<script>
import axios from "axios";
import addCarContractCard from "@/components/addNewCard.vue";
import carConractAddInvoice from "@/components/addInvoice.vue";
import carConractAddQuot from "@/components/addQuotation.vue";
export default {
  components: {
    addCarContractCard,carConractAddInvoice,carConractAddQuot
    
  },
  data() {
    return {
      id: 0,
      project: {
        customer_contacts: [{mobile: ''}]
      }
    };
  },
  computed: {
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },
    is_mobile: function(){
        return false;
    },
    Statics: function () {
      let t = {
        crmActions: {},
        crmStatus: {},
        crmType: {},
      };
      for (let i = 0; i < this.staticOption.length; i++) {
        if (this.staticOption[i].option_phrase == "crmActions") {
          t.crmActions[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        } else if (this.staticOption[i].option_phrase == "crmStatus") {
          t.crmStatus[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        } else if (this.staticOption[i].option_phrase == "crmType") {
          t.crmType[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        }
      }
      return t;
    },
  },
  methods: {
    resetAllValues(){
      this.$refs.addCarContractCard.resetValues();
      this.$refs.addCarContractCard.getInvSettings();
      this.$refs.addCarContractCard.customer.customerid = this.project.customerid;
      this.$refs.addCarContractCard.customer.id = this.project.customerid;
      // console.log(this.project.customerid);
      this.$refs.addCarContractCard.getAllNameById(0);
      this.$refs.addCarContractCard.contract_id = this.id;
      this.$refs.addCarContractCard.$refs.firstCar.image = '';
    },
    resetAllValuesS(){
      // this.$refs.addCarContractCard.resetValues();
      // this.$refs.addCarContractCard.getInvSettings();
      // this.$refs.addCarContractCard.customer.customerid = this.project.customerid;
      // this.$refs.addCarContractCard.customer.id = this.project.customerid;
      // console.log(this.project.customerid);
      // this.$refs.addCarContractCard.getAllNameById(0);
      // this.$refs.addCarContractCard.contract_id = this.id;
    },
    addInvoiceEd(){
      this.$refs.addCarInvoice.reset();
      this.$refs.addCarInvoice.customer.customerid = this.project.customerid;
      this.$refs.addCarInvoice.contract_id = this.id;
      this.$refs.addCarInvoice.getAllNameById(0);
    },
    addQuot(){
        this.$refs.addQuot.contract_id = this.id;
        this.$refs.addQuot.id = 0;
        this.$refs.addQuot.tbrows = [];
        this.$refs.addQuot.car = {
                model: '',
                plate_number: '',
                color: '',
                made_year: ''
            }
        this.$refs.addQuot.newrow = {
                itemcode: '',
                description: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0,
                plate_number: '',
                discount: '',
            }
        this.$refs.addQuot.cars = [{
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
            }]
        this.$refs.addQuot.customer.id=this.project.customerid;
        this.$refs.addQuot.customer.mobile=this.project.customer_contacts[0].mobile;
        this.$refs.addQuot.customer.full_name='';
        this.$refs.addQuot.customer.cctype=2;
        this.$refs.addQuot.company.fullname='';
        this.$refs.addQuot.company.crt_number='';
        this.$refs.addQuot.company.vatid='';

        this.$refs.addQuot.changeCusType(1);
        this.$refs.addQuot.getName();

      },
    addNote() {
      this.$refs.addnotes.crm_id = this.id;
    },
    addTask() {
      this.$refs.addCarContractCard.contract_id = this.project.contract_id;
      this.$refs.addCarContractCard.serviceList = this.project.contract_items;
    },
    addInvoice() {
      this.$refs.addCarInvoice.contract_id = this.project.contract_id;
      this.$refs.addCarInvoice.serviceList = this.project.contract_items;
    },
    addQuotation() {
      this.$refs.addCarQuot.contract_id = this.project.contract_id;
      this.$refs.addCarQuot.serviceList = this.project.contract_items;
    },
    printPDF(id) {
      // if(navigator.share){
      //     navigator.share({
      //         files: [new File([this.readFile('../api/print.php?invid='+id)],'file.pdf',{type: Blob.type})],
      //         title: '',
      //         text: '',
      //     })
      // }else{
    //   let pdfFrame = document.body.appendChild(document.createElement("iframe"));
    //   pdfFrame.style.display = "none";
    //   pdfFrame.src = "../api/print.php?contractJob=" + id;
    //   pdfFrame.onload = () => void pdfFrame.contentWindow.print();
      // }
      window.open("../api/print.php?contractJob=" + id,"_blank")
    },
    getContracts(id) {
      if (id == 0) {
        return false;
      }
        this.id = id;
        const post = new FormData(); 
        post.append("type","getCarContracts");
        post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
        post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append("data[id]",id)
        axios.post(
            this.$SAMCOTEC.r_path,post
        ).then((response) => {
            if(response && response.data && response.data.results && response.data.results.length != 0){
                this.project = response.data.results.data[0];
            }
        })
    },
    doComputer(id) {
      if (id == 0) {
        return this.lang.no_devices;
      } else if (id == 1) {
        return this.lang.yes_19;
      } else if (id == 2) {
        return this.lang.yes_24;
      }
    },
    async getStaticOp() {
      let post = new FormData();
      post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
      let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
      const path = this.$router.currentRoute.path;
      post.append("type", "getStaticOptions");
      post.append("auth", cook);
      post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
      post.append("data[test]", "1");
      const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post);
      if (response && response.data) {
        this.staticOption = response.data.results.data;
      }
    },
  },
  created() {
    this.getStaticOp();
  },
};
</script>
